import { Component, OnInit , TemplateRef , Inject, HostListener} from '@angular/core';
import { NodeService } from '../node.serive';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BotdataService } from '../services/botdata.service';
import { switchMap } from "rxjs/operators"
import { Route } from '@angular/compiler/src/core';
import { __param } from 'tslib';
import { ModalComponent } from '../modal/modal.component';
import { ToastrService } from 'ngx-toastr';
import { ChatbotListService } from '../services/chatbotlist/chatbot-list.service';

@Component({
  selector: 'app-bot-container',
  templateUrl: './bot-container.component.html',
  styleUrls: ['./bot-container.component.less']
})
export class BotContainerComponent implements OnInit {

  nodes = [];
  data:any;
  connections = [];
  entryNodeCount:number;
  bot_id : number = 0;
  showOtherTab : boolean = false;
  bot_name : any;
  oldBot_name:any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  modalRef: BsModalRef;
  is_active:any;
  editInput:any = false;
  start_at:any=null;
  end_at:any=null;
  bot_detail:any={};
  chatObj:any={};
  organizationId:any;
  orgId : any;
  noOfNodes : number;
  sreenSize=100;


  ngOnInit() {

    this.bot_id = this.route.snapshot.params["id"];
    this.organizationId = sessionStorage.getItem("botifier-workflow-org-id");
    this.orgId = '/'+this.organizationId;
    if(!sessionStorage.getItem("botifier-workflow-bot-id")){
      this.goHome();
    }
    //else if(!sessionStorage.getItem("channel_group_name")){
    //   this.goHome();
    // }
    // if(!sessionStorage.getItem("botifier-workflow-org-id")){
    //   this.router.navigate([""]);
    // }

    //|| !sessionStorage.getItem("channel_group_name") || !sessionStorage.getItem("bot_name")


    // ***************** for entry node ***********
    this.botDataService.entryNodeCreated.subscribe(node=>{

        if(node != {} && node != "" && node != undefined){
            if(node["flag"] == true && node["id"] == this.bot_id){
              this.showOtherTab = true;
            }else if(node["flag"] == false && node["id"] == this.bot_id  && node["openEntryModal"] == true){
              this.dialog.open(ModalComponent, { data: {  node_type: "ENTRY", node_sub_type: ["INCOMING_MSG", "EVENT"] , bot_id : this.bot_id }});
            }  
            if(node["id"]){
              sessionStorage.setItem("botifier-workflow-bot-id" , node["id"]);
            }
            if(node["name"]){
                this.bot_name = node["name"];
                this.oldBot_name=node["name"];
                sessionStorage.setItem("bot_name" , this.bot_name);
            }
            this.is_active = node["is_active"];

            if(node["noOfNodes"]){
               this.noOfNodes = node["noOfNodes"];
            }

            if(node["channel_type"] && node["id"] && node["organization_id"] && node["channel_detail_id"]){
               this.bot_detail = node;
            }
        }    
    })
  }

    constructor(private botDataService: BotdataService,private modalServices: BsModalService,
    private nodeService: NodeService,private route: ActivatedRoute, public chatbotservice:ChatbotListService,
    public dialog: ModalService , private toastr: ToastrService,private router:Router,public modalService : ModalService
    ) {     
    }

    goHome(){
      sessionStorage.removeItem("botifier-workflow-bot-id");
      sessionStorage.removeItem("channel_group_name");
      sessionStorage.removeItem("bot_name");
      this.router.navigate([this.orgId]);
    }

  changeToEditField(){
      this.editInput = true;
  }

  declineEditField(){
    this.editInput = false;
    this.bot_name=this.oldBot_name;
  }


  charonly(event: any){
    const pattern =/^[a-zA-Z0-9_-]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }

  removeSpace(event:any){
    let val =  event.target.value;
    val = val.replace(/ /g,'');
    this.bot_name = val;
  }


  submitEditField(newName){
    if(newName != undefined && newName != ""){
        delete this.bot_detail["openEntryModal"];
        delete this.bot_detail["flag"];
        this.bot_detail["name"]=newName;
        this.chatbotservice.updatechatBot(this.bot_detail).subscribe(
          (response) => {
            if (response.statusCode=="200") {
              this.chatObj = response["result"];
              this.botDataService.showOtherTabs(this.chatObj);
              this.bot_name = newName;
              this.editInput = false;
              this.toastr.success('Workflow name has been updated successfully');
            }
          }
          // \,
          // (error: Response) => {
          //   this.bot_name = this.oldBot_name;
          //   this.editInput = false;
          //   //this.toastr.error(response["message"]);
          // }
          );
    }else{
      this.toastr.error('Workflow name can not be blank');
    }     
  }


  
  fillFromJson() {
    const json = `{"nodes":[{"id":"Step_0 id: b46a17","top":177,"left":146},{"id":"Step_1 id: efd2ce","top":302,"left":130},{"id":"Step id_2eb091","top":41,"left":158}],"connections":[{"uuids":["Step_0 id: b46a17_bottom","Step_1 id: efd2ce_top"]},{"uuids":["Step id_2eb091_bottom","Step_0 id: b46a17_top"]}]}`;
    const data = JSON.parse(json);  
    this.nodes = data.nodes;
    this.connections = data.connections;
  }
  openEntryNodeModal()
  {
      let nodes:any = this.botDataService.dataToTransmit , breakLoop:boolean = false , typeCount:number = 0;  
      nodes.forEach(node => {      
        if(breakLoop == false){
            if(node.node_type != 'ENTRY'  && node.bot_id == this.bot_id){
                typeCount++;
            }else if(node.node_type == 'ENTRY'){
                breakLoop = true;     
            }  
        }  
      })

      if(typeCount == nodes.length){
        const ref = this.dialog.open(ModalComponent, { data: {  node_type: "ENTRY", node_sub_type: ["INCOMING_MSG", "EVENT"] , bot_id : this.bot_id }});
      }else{
        this.toastr.info("Entry node is already present");
      }
      this.closeModel();
  }
 
    openActionModal()
    {
      const ref = this.dialog.open(ModalComponent, { data: { node_type: "ACTION" ,node_sub_type: ["OUTBOUND", "API","WEBHOOK","USER_INPUT","STAR RATING","STOP","WORKFLOW_TRIGGER","DEPARTMENT_SWITCHER","LOOP","ASSIGN_AGENT","LLM_AI"],bot_id : this.bot_id},}) ;
      this.closeModel();
    }
    openConditionlNode()
    {
      const ref = this.dialog.open(ModalComponent, { data: { node_type: "CONDITIONAL" ,conditionnode_type:["IF_ELSE","WAIT_UNTIL","DELAY"] , bot_id : this.bot_id}});
      this.closeModel();
    }

    closeModel()
    {
      this.router.events
      .subscribe(() => {
      this.modalService.removeDialogComponentFromBody();
    });
    }
 
  openModal(template: TemplateRef<any>,id , type) {
    if(type=='resume' && this.noOfNodes == undefined){
      this.toastr.success('Workflow can not be resumed . There is no node present in this workflow');
    }else if((this.noOfNodes != undefined && (type=='resume' || type=="pause" || type == 'delete'))||(this.noOfNodes == undefined && type=='delete')){
        this.modalRef = this.modalServices.show(template,this.config);
        this.bot_id=id; 
    }
  }

  declineModal(): void { 
    this.modalRef.hide();
  }

  confirmPause(chat_id): void {  
    this.chatbotservice.pauseById(chat_id)
    .subscribe(data  => {    
      this.toastr.success('Workflow has been paused successfully');
      this.is_active = false;
      },
      error  => {
      this.toastr.error('Something went wrong, please try again');
      });
    this.modalRef.hide();
  }

  confirmResume(chat_id):void{  
        this.chatbotservice.resumeById(chat_id)
        .subscribe(data  => {  
         if (data.statusCode=="200") {
         this.toastr.success('Workflow has been resume successfully');
          this.is_active = true;
        }else if (data.statusCode== 400){
          this.toastr.error(data["message"]);
        }
      },
      (error: Response) => {
        
      }
   
    );
     this.modalRef.hide();
  }

  confirm(chat_id): void {  
    this.chatbotservice.delById(chat_id)
    .subscribe(result  => {
      this.toastr.success('Workflow  deleted successfully');
      sessionStorage.removeItem("botifier-workflow-bot-id");
      sessionStorage.removeItem("channel_group_name");
      sessionStorage.removeItem("bot_name");
      this.router.navigate(["/",this.organizationId]);
      },
      error  => {
        this.toastr.error('Something went wrong, please try again');
       });
      this.modalRef.hide();
  }

  ZoomIn()
  {
    if(this.sreenSize<100)
    {
      this.sreenSize=this.sreenSize+10;
    }
  }
  ZoomOut()
  {
    if(this.sreenSize>30 )
    {
      this.sreenSize=this.sreenSize-10;
    }
  }

}
