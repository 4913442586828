import { Component, Input, AfterViewInit, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NodesService } from '../services/nodes/nodes.service';
import { BotdataService } from '../services/botdata.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
import { ToastrService } from 'ngx-toastr';
import { nextNodeUpdate } from '../utils/nextNodeUpdate';
import { DialogRef } from '../dialog/dialogRef';
import { Subscription } from 'rxjs';
import { ChatbotListService } from '../services/chatbotlist/chatbot-list.service';
import { forEach } from '@angular/router/src/utils/collection';
import { checkBranching } from '../utils/checkBranching';
import {endpointManage} from '../utils/endpointManage';
import { ContextMenuComponent,ContextMenuService } from 'ngx-contextmenu';
import { node_type_map,node_sub_type_map } from '../constants/constantVar';


export interface Node {
  node_id: string;
  node_type: string;
  node_sub_type: string;
  top?: number;
  count : number;
  left?: number;
  id: number;
  node_data: any;
  node_name: any;
  refreshFlag: boolean;

}
@Component({
  selector: 'node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.less'],
})
export class NodeComponent implements OnInit, AfterViewInit {

  //@HostListener('window:scroll', ['$event'])


  @ViewChild('basicMenu') contextMenu: ContextMenuComponent

  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  //viewContainerRef =  ViewContainerRef;
  bot_id: any;
  nodes: any;
  count:number=0;
  subscription: Subscription;
  nodeData: {};
  nodeId: any;
  source
  target
   nodeList:any;
   mySubscription: any;
   shoeContextMenu:boolean=false;
  @Input() node: Node;
  @Input() jsPlumbInstance;
  @Input() refreshStatus;

  nodeTypeMap: object = node_type_map;
  nodeSubTypeMap: object = node_sub_type_map;
  nodeType: string;
  isLoading: boolean = false;
  refreshFlag: boolean = true;
  chatObj: any;
  branching:boolean=false;

  constructor(private activeRoute: ActivatedRoute, public dialog: ModalService, public dialogRef: DialogRef,
    private modalService: BsModalService, private nodeServices: NodesService,private contextMenuService: ContextMenuService,
    private botDataService: BotdataService, private commonService: CommonService,
    private toastr: ToastrService, private router: Router, public chatbotservice: ChatbotListService
  ) { }


  ngOnInit(): void {
    this.nodeType = this.node.node_type;
  }


  nodeRightClickValid($event: MouseEvent, item: any){
    $event.preventDefault();
          let nodeList:any=this.botDataService.dataToTransmit;
           let node=item;
          nodeList.forEach(element => {
            if(element.node_id == item.node_id){
                node=element;
                this.node=element;

       if(node != null && node != undefined && node.node_sub_type == "USER_INPUT" &&  node.node_data.inbound.input.branching){

           if(node.node_data.next_node.branch_nodes_id != null){
            let branchingNodeJson=node.node_data.next_node.branch_nodes_id;
             let count=0;
            let arrayOfBranching=Object.entries(branchingNodeJson).map(([key, value]) => ({key,value}));
            arrayOfBranching.forEach(element => {
              if(element.value ==null && element.value == undefined){
                  count++;
              }
            });

            if(count ==0){
                this.shoeContextMenu=false;
            }else{
              this.shoeContextMenu=true;
                this.contextMenuService.show.next({
                contextMenu: this.contextMenu,
                event: $event,
                item: node

              });

              $event.stopPropagation();
            }
           }
        }else{
          if(node.node_sub_type != "DUMMY" && node.node_sub_type != "STOP" && node.node_sub_type != "IF_ELSE" && (node.node_data.next_node.node_id == null || node.node_data.next_node.node_id.trim() == "")){
             this.contextMenuService.show.next({
                contextMenu: this.contextMenu,
                event: $event,
                item: node

              });
              $event.preventDefault();
              $event.stopPropagation();
          }
        }

   }
          });
  }


  showMessage(message: any) {
      if(message=="action"){
           const ref = this.dialog.open(ModalComponent, { data: { node_type_clickable: true,node_type: "ACTION", node_dummy: false, edit_data: this.node, node_sub_type: ["OUTBOUND", "API","WEBHOOK","USER_INPUT","STAR RATING","STOP","WORKFLOW_TRIGGER","DEPARTMENT_SWITCHER","LOOP","ASSIGN_AGENT","LLM_AI"]}} );
      }else{
             const ref = this.dialog.open(ModalComponent, { data: {  node_type_clickable: true, node_type: "CONDITIONAL", node_dummy: false, edit_data: this.node, conditionnode_type: ["IF_ELSE","WAIT_UNTIL","DELAY"]}} );
      }
      }


  openModal() {
    this.nodeServices.Loading = true;
    if (this.node.id) {
      this.nodeServices.getNode(this.node.id).subscribe(response => {
        this.node = response['result'];
        this.nodeServices.Loading = false;
        let botId=this.node['bot_id'];
        if(this.node.node_sub_type =="DUMMY"){
            const ref = this.dialog.open(ModalComponent, { data: { node_type_Dummy: "DUMMY" , node_dummy: false, edit_data: this.node,node_sub_type: ["OUTBOUND", "API","WEBHOOK","USER_INPUT","STAR RATING","STOP","WORKFLOW_TRIGGER","DEPARTMENT_SWITCHER","LOOP","IF_ELSE","WAIT_UNTIL","DELAY","ASSIGN_AGENT","LLM_AI"]}} );
        }else{
          const ref = this.dialog.open(ModalComponent, { data: { edit_data: this.node,  node_dummy: true, flag: true } });
        }
       // const ref = this.dialog.open(ModalComponent, { data: { edit_data: this.node, flag: true } });
      })
    }
    this.router.events
      .subscribe(() => {
        this.dialog.removeDialogComponentFromBody();
      });

  }

  opendelModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template, this.config);
    this.router.events
      .subscribe(() => {
        this.modalRef.hide();
      });
  }
  confirm(id, node_id, bot_id): void {
    this.nodeServices.Loading = true;
    this.refreshFlag = true;

      this.nodeList = this.botDataService.dataToTransmit;
      this.nodeServices.delById(id, node_id)
      .subscribe(resp => {
            this.nodeServices.Loading = false;
            this.toastr.success('Node Deleted Successfully');
            if(this.node.node_data.parent_nodes_type.length > 0){
            let parantNodeId= this.node.node_data.parent_nodes_type[0].node_id
            let parantNodeKey= this.node.node_data.parent_nodes_type[0].branching_node_key

               this.nodeList.forEach(node => {
                  if(node.node_id==parantNodeId){
                    if(node.node_data.next_node.branch_nodes_id != undefined && node.node_data.next_node.branch_nodes_id != null){
                       let branchingObj=Object.entries(node.node_data.next_node.branch_nodes_id).map(([key, value]) => ({key,value}));
                       branchingObj.forEach(element => {
                         if(element.value==this.node.node_id){
                            node.node_data.next_node.branch_nodes_id[element.key]=null;
                         }
                       });
                    }else{
                      node.node_data.next_node.node_id=null;
                    }
                    if(this.node.node_id == node.node_data.next_node.yes_node_id){
                      node.node_data.next_node.yes_node_id=null;
                    }
                    if(this.node.node_id == node.node_data.next_node.no_node_id){
                       node.node_data.next_node.no_node_id=null;
                    }
                    node.node_data.links.forEach(nodeLink => {
                    if (nodeLink.y[0] == this.node.node_id + '_top') {
                      node.node_data.links = node.node_data.links.filter(item => item.y[0] != this.node.node_id + '_top');
                    }
                  });
                  // let newLinkPostion=endpointManage.chnageEndpoint(node.node_data.links,node.node_id);
                  // node.node_data.links=newLinkPostion;


                     this.nodeServices.updateNodeData(node, node.id).subscribe((res) => {
                             if (res && res['statusCode'] == 200) {
                               let deleteNodeId=node_id
                               this.nodeServices.setNodeDeleteId(deleteNodeId);
                               // window.location.reload();
                             }
                             });

                  }

              })
      }else{
        let deleteNodeId=node_id
        this.nodeServices.setNodeDeleteId(deleteNodeId);
      }},
        error => {
          this.nodeServices.Loading=false;
          this.toastr.error('Something went wrong, please try again');
        });
    this.modalRef.hide();
  }
  decline(): void {
    this.modalRef.hide();
  }

  onClose() {
    this.dialogRef.close('some value');
  }

  ngAfterViewInit() {
      if(this.refreshStatus){
        console.log(this.refreshStatus);

      }
    const { node_id } = this.node;
    const { node_type } = this.node;
    const { node_sub_type } = this.node;
    if(this.node.node_data.inbound && node_sub_type =="USER_INPUT"){
       let uniqueOptions = checkBranching.getUniqueListBy(this.node.node_data.inbound.input.options, "value");
       this.count = uniqueOptions.length >= 1 ? uniqueOptions.length : 1;
       this.branching=this.node.node_data.inbound.input.branching;

    }



    else{
        this.count  = 1 ;
    }


    const exampleDropOptions = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive'
    };
    let bottom_conn: number = 0, top_conn: number = 0;
    let endpointBoton: any;
    if (node_sub_type == 'IF_ELSE') {
      bottom_conn = 1;
    } else {
      bottom_conn = 1;

    }

let endponts=[];
    // if (node_sub_type =="USER_INPUT" && this.count != null && this.count != undefined ) {
    //   bottom_conn=this.count;
    //   // for (let index = 0; index < this.count; index++) {
    //   //  endponts.push('Dot', { radius: 7 });

    //   // }

    // } else {
    //   bottom_conn = 1;
    // }
    if (node_sub_type == 'STOP') {
      top_conn = 10;
    } else {
      top_conn = 10;
    }



    let bottomEndpoint1 = {
      endpoint: ['Dot', { radius: 7 ,cssClass : 'top-class'}],
      paintStyle: { fill: '#4772dc' },
      isSource: true,
      scope: 'jsPlumb_DefaultScope',
      connectorStyle: { stroke: '#4772dc', strokeWidth: 2 },
      connector: ['Flowchart', {  curviness: 1, stub: 10 ,cornerRadius: 5,}],
      maxConnections: bottom_conn,
      isTarget: false,
      connectorOverlays: [['Arrow', { location: 1 }]],
      dropOptions: exampleDropOptions

    };

    // let Endpoint3 = {
    //   endpoint: ['Dot', { radius: 7 }],
    //   paintStyle: { fill: '#99cb3a' },
    //   isSource: true,
    //   scope: 'jsPlumb_DefaultScope',
    //   connectorStyle: { stroke: '#99cb3a', strokeWidth: 3 },
    //   connector: ['Flowchart', { curviness: 63 }],
    //   maxConnections: bottom_conn,
    //   isTarget: false,
    //   connectorOverlays: [['Arrow', { location: 1 }]],
    //   dropOptions: exampleDropOptions

    // };

    let topEndpoint2 = {
      endpoint: ['Dot', { radius: 5 }],
      paintStyle: { fill: '#ffcb3a' },
      isSource: false,
      scope: 'jsPlumb_DefaultScope',
      maxConnections: top_conn,
      isTarget: true,
      dropOptions: exampleDropOptions,
    };

    if (node_sub_type != 'STOP' && node_sub_type != 'IF_ELSE' && node_sub_type != 'DUMMY' && this.branching ==false) {
     let s1 =  this.jsPlumbInstance.addEndpoint(node_id, { anchor: 'Bottom', uuid: node_id + '_bottom'}, bottomEndpoint1)
    }
    if (node_type != 'ENTRY') {
      let t1 = this.jsPlumbInstance.addEndpoint(node_id, { anchor: 'Top', uuid: node_id + '_top' }, topEndpoint2)
    }

    if (node_sub_type == 'IF_ELSE') {
     let sr = this.jsPlumbInstance.addEndpoint(node_id, { anchor: ["Right",], uuid: node_id + '_Right'}, bottomEndpoint1);
     let sl = this.jsPlumbInstance.addEndpoint(node_id, { anchor: ["Left",], uuid: node_id + '_Left'}, bottomEndpoint1);
    }
    // if (node_sub_type == 'DUMMY') {
    //  let sl = this.jsPlumbInstance.addEndpoint(node_id, { anchor: ["Top",], uuid: node_id + '_top',ConnectionsDetachable:true},topEndpoint2);

    // }
    // if (node_sub_type == 'USER_INPUT' && this.count > 1) {
    //     for (let index = 1; index <= this.count; index++) {
    //       let position;
    //       if(this.count ==1){
    //         position=0.5
    //       }else{
    //         position=0.4
    //       }
    //         let s1 =  this.jsPlumbInstance.addEndpoint(node_id, { anchor: [position, 1, 0, 1, 0, 0, "Bottom"], uuid: node_id + '_bottom_'+index}, bottomEndpoint1)
    //     }
    // }
    if (node_sub_type == 'USER_INPUT' && this.count >= 1 && this.branching) {
      let position = 0.00;
      let increased_by = 1 / (this.count + 1);
      for (let index = 1; index <= this.count; index++) {
        position = position + increased_by;
        position = Math.round (position * 100) / 100;
        let nodeUuid = node_id + '_bottom_'+ position;
        console.log(nodeUuid);
        this.jsPlumbInstance.addEndpoint(node_id, { anchor: [position, 1, 0, 1, 0, 0, "bottom"], uuid: nodeUuid}, bottomEndpoint1);
      }
    }

    const nodeAfterCreate = this.jsPlumbInstance.getContainer();
    const updateService: any = this.nodeServices;
    const botDataService: any = this.botDataService;
    const scrollPosition = this.scrollPosition;
    const toastr: any = this.toastr;
    const jsplumbConst = this.jsPlumbInstance;
    let targetarr: any = [];

    // connection update code starts ***********
    this.jsPlumbInstance.unbind("connectionDetached");
    this.jsPlumbInstance.bind('connectionDetached', function (connection, e) {
      console.log(connection);
      let nodes = botDataService.dataToTransmit;
        let parantObjectOptionValue:any;
        let branching:boolean=false;
      if (e != undefined) {
        nodes.forEach(nodea => {



          if (connection.sourceId == nodea.node_id) {

            if (nodea.node_sub_type == "IF_ELSE") {
              nodea.node_data.links.forEach(nodeLink => {
                (nodeLink.y).forEach(y => {
                if (y == connection.targetId + '_top') {
                  nodea.node_data.links.splice(nodea.node_data.links.indexOf(nodeLink), 1);
                }
              });
              });

              if (connection.targetId == nodea.node_data.next_node.yes_node_id) {
                nodea.node_data.next_node.yes_node_id = null;
              } else if (connection.targetId == nodea.node_data.next_node.no_node_id) {
                nodea.node_data.next_node.no_node_id = null;
              }
            } else {
              if(nodea.node_sub_type == "USER_INPUT"){
              branching=nodea.node_data.inbound.input.branching
              }else{
                branching=false;
              }
                 nodes.forEach(nodeChild => {
                     if(connection.targetId == nodeChild.node_id){
                      let parentType = nodeChild['node_data']['parent_nodes_type'];
                          if(parentType.length > 0 && parentType){
                               parentType.forEach((val , key)=>{
                                if(val.node_id == connection.sourceId){
                                 // if(val.branching_node_key){
                                    parantObjectOptionValue=nodeChild.node_id;
                                   // }
                                }
                })

                          }}

                 })
              if(branching){
                 nodea.node_data.links.forEach(nodeLink => {
                if (nodeLink.y[0] == connection.targetId + '_top') {
                  nodea.node_data.links = nodea.node_data.links.filter(item => item.y[0] != connection.targetId + '_top');
                }
              });
              }else{
              nodea.node_data.links =[];
              }
              let branchingNodeJson =nodea.node_data.next_node.branch_nodes_id;
              if(branchingNodeJson != null && branchingNodeJson != undefined){
              let arrayOfBranching=Object.entries(branchingNodeJson).map(([key, value]) => ({key,value}));
              arrayOfBranching.forEach(e => {
                        if(e.value== parantObjectOptionValue){
                           // let key=e.value;
                          branchingNodeJson[e.key]=null;
                        }
                     });
                     nodea.node_data.next_node.branch_nodes_id=branchingNodeJson;
                     parantObjectOptionValue=null;
              }
              nodea.node_data.next_node.node_id = null;
            }

            if(nodea.node_data.counter_value) {
              delete nodea.node_data.counter_value;
            }





            if (nodea != undefined && nodea != "" && nodea != null && nodea.id > 0) {
              updateService.updateNodeData(nodea, nodea.id).subscribe((response) => {
                if (response.statusCode == 200) {

                  let nodes:any = [];
                  nodes = botDataService.dataToTransmit;
                  nodes.forEach((node , i)=>{
                        if(node.node_id == response["result"].node_id){
                            nodes.splice(i,1,response["result"]);
                        }
                  })
                  botDataService.dataToTransmit = nodes;
                  this.chatObj = {};
                  this.chatObj["is_active"] = false;
                  this.chatObj["name"] = response["result"].name;
                  this.chatObj["bot_id"] = response["result"].bot_id;
                  botDataService.showOtherTabs(this.chatObj);
                  toastr.success('Node updated successfully');
                  if(branching){

                   // window.location.reload();
                  }
                }
                this.node = response;
              });
            }
          }

          if(connection.targetId == nodea.node_id){
           let parentType = nodea['node_data']['parent_nodes_type'];
              if(parentType.length > 0 && parentType){
                parentType.forEach((val , key)=>{
                  if(val.node_id == connection.sourceId){
                      parentType.splice(key , 1);
                   //delete val.node_id;
                   //delete val.node_type;
                  }
                })
              }
              nodea['node_data']['parent_nodes_type']=parentType;
              updateService.updateNodeData(nodea, nodea.id).subscribe((response) => {
                if (response.statusCode == 200) {
                }
              })
          }

        });
        //  }
      }
    });


  this.jsPlumbInstance.unbind("connection");
  this.jsPlumbInstance.bind("connection", function (info, e) {
      console.log(info);
       let branching:boolean=false;
      let conid = info.connection.id;
    if(info.sourceId == info.targetId){
      var connections = jsplumbConst.getConnections({
        source: info.sourceId ,
        target: info.targetId
      });
      for(var i=0;i < connections.length;i++) {
        jsplumbConst.deleteConnection(connections[i]);
      }
      //jsplumbConst.detach(info);
    }else{

        let nodes = botDataService.dataToTransmit;
          if (e != undefined) {
            let anctorType: any="top";
            let branchingLabel:any;
            let ancorTypeArray = info.sourceEndpoint.anchor.anchors;
            let ancorTypeX=info.sourceEndpoint.anchor.x;
            console.log(ancorTypeArray);
            if(ancorTypeArray){
              for (let i of ancorTypeArray) {
                anctorType = i.type;
              }
            }
            let PreviousDetachId=info.connection.suspendedElementId;
            if(PreviousDetachId != null && PreviousDetachId != undefined ){
              nodes.forEach(node => {
              if (PreviousDetachId == node.node_id) {
                  node.node_data.parent_nodes_type=[];
                    //node.node_data.next_node.branch_nodes_id=null;
                     updateService.updateNodeData(node, node.id).toPromise().then(response => {
                  if (response.statusCode == 200) {
                      console.log("success");
                  }
                }) ;
              }

            });
            }

            targetarr = [];
            let nodeData: any = {}, targetNodeData :any = {}, nodeId: number = 0;
            let nextNpdeId: string = null ,
            sourceNodeId:number =0 , targetNodeId:number =0 , targetNodeName:any="";

            nodes.forEach(nodea => {
              if (info.targetId == nodea.node_id) {
                nextNpdeId = nodea.node_id;
                targetNodeId = nodea.id;
                targetNodeName = nodea.node_sub_type;
                targetNodeData = nodea;


              }
            });

            nodes.forEach(node => {
              if (info.sourceId == node.node_id) {
                sourceNodeId = node.id;


                let nodeTop;
                nodeId = node.id;
                 if (node.node_sub_type == "USER_INPUT") {
                    branching=node.node_data.inbound.input.branching
                    // node.node_data.links.forEach(item=>{
                    //   targetarr.push(item.y[0]);
                    // });
                  }
                if (targetarr != undefined) {
                  if (node.node_sub_type != "IF_ELSE" && node.node_sub_type != "USER_INPUT") {
                    node.node_data.links.forEach(x=>{
                      x.x=[];
                      x.y=[];
                    });
                  }


                  if (!targetarr.includes(info.targetId + '_top')) {
                      targetarr.push(info.targetId + '_top');
                  }
                   if (PreviousDetachId != undefined) {
                     node.node_data.links.forEach(x=>{
                       node.node_data.links=node.node_data.links.filter(item => item.y[0] != PreviousDetachId + '_top')
                     });
                   //  targetarr= targetarr.filter(item => item != PreviousDetachId + '_top');
                  }
                  //update next node id
                  let inputObjectNextnode = nextNodeUpdate.updateInput(node, nextNpdeId , anctorType)
                  nodeId = node.id;
                  if( inputObjectNextnode.node_data.links ){
                    inputObjectNextnode.node_data.links.forEach(element => {
                      if(element.x=="" || element.x==info.sourceId + '_'+anctorType ){
                        inputObjectNextnode.node_data.links.splice(inputObjectNextnode.node_data.links.indexOf(element), 1);
                      }

                  });
                  }

                  if (anctorType == "Right") {
                    nodeTop= info.sourceId + '_Right';
                    info.connection.addOverlay(
                      ["Label", {
                        location:0.5,
                          label: "False",
                          cssClass: "conn-label-class",
                          visible: true,
                        }
                      ]);
                    }
                  if (anctorType == "Left") {
                    nodeTop= info.sourceId + '_Left';
                    info.connection.addOverlay( ["Label", {
                      location:0.5,
                        label: "True",
                        cssClass: "conn-label-class",
                        visible: true,
                      }
                    ]);
                  }
                  if(anctorType == "top") {
                    if(node.node_sub_type == "USER_INPUT" && node.node_data.inbound.input.branching){
                      nodeTop=info.sourceId + '_bottom_'+ancorTypeX;
                    }else{
                    nodeTop=info.sourceId + '_bottom';
                    }
                  }



                  let nodeTopAndBotton=new Map();
                  nodeTopAndBotton.set("x",nodeTop);
                  nodeTopAndBotton.set("y",targetarr);

                    let nodeLink=[...nodeTopAndBotton].reduce((o, [key, value]) => (o[key] = value, o), {})
                    inputObjectNextnode.node_data.links.push(nodeLink);


                    nodeData = {
                      bot_id: inputObjectNextnode.bot_id,
                      node_id: inputObjectNextnode.node_id,
                      node_name: inputObjectNextnode.node_name,
                      node_type: inputObjectNextnode.node_type,
                      node_sub_type: inputObjectNextnode.node_sub_type,
                      node_data: inputObjectNextnode.node_data,
                      goal_name:inputObjectNextnode.goal_name,
                      goal_value:inputObjectNextnode.goal_value,
                      goal_unit:inputObjectNextnode.goal_unit


                    }
              if(nodeData.node_sub_type== "USER_INPUT" && node.node_data.inbound.input.branching){
                let sourceInputoption=nodeData.node_data.inbound.input.options
                nodeData.node_data.next_node.node_id=null;
                let sourceBranchingMap=nodeData.node_data.next_node.branch_nodes_id;
                 if(PreviousDetachId != undefined && PreviousDetachId != null){
                     let arrayOfBranching=Object.entries(sourceBranchingMap).map(([key, value]) => ({key,value}));
                    arrayOfBranching.forEach(e => {
                              if(e.value== PreviousDetachId){
                                sourceBranchingMap[e.key]=info.targetId;
                                 targetNodeData.node_data.parent_nodes_type[0]={"node_id":nodeData.node_id, "node_type":nodeData.node_sub_type,
                                "branching_node_key":e.key };
                                info.connection.setDetachable(true);
                                 info.connection.addOverlay(
                                    ["Label", {
                                      location:0.5,
                                        label: e.key,
                                        cssClass: "conn-label-class",
                                        visible: true,
                                      }
                                    ]);
                              }
                          });

                    }else{
                        for (let index = 0; index < sourceInputoption.length; index++) {
                          let option=sourceInputoption[index];

                        if(sourceBranchingMap[option.value] == null || sourceBranchingMap[option.value] == undefined ){
                            sourceBranchingMap[option.value]=info.targetId;
                            targetNodeData.node_data.parent_nodes_type[0]={"node_id":nodeData.node_id, "node_type":nodeData.node_sub_type,
                            "branching_node_key":option.value };
                            info.connection.setDetachable(true);
                            info.connection.addOverlay(

                              ["Label", {
                                location:0.5,
                                  label: option.value,
                                  cssClass: "conn-label-class",
                                  visible: true,
                                }
                              ]);
                            break;
                          }

                        }
                    }
                // sourceInputoption.forEach(option => {

                // });
                }
              let parentObj = {} , parentNodeTypeCount : number =0 , parentType : any , parentIdAlreadyPresent :boolean = false , loopCounter : number = 10;;
              parentObj["node_id"] = nodeData['node_id'];
              parentObj["node_type"] = nodeData['node_sub_type'];
              parentType = targetNodeData['node_data']['parent_nodes_type'];

              if(parentType.length > 0 && parentType){
                parentType.forEach((val , key)=>{
                  if(!parentIdAlreadyPresent){
                    if(val.node_id == parentObj["node_id"]){
                      parentIdAlreadyPresent = true;
                       return false;
                    }
                  }
                })
                if(!parentIdAlreadyPresent){
                   parentType.forEach((val , key)=>{
                     if(val.branching_node_key){
                       let branchingMap=new Map();
                       branchingMap.set(val.branching_node_key,info.targetId)
                       let branchingObj=[... branchingMap].reduce((o, [key, value]) => (o[key] = value, o), {});;
                    if(nodeData.node_data.next_node.branch_nodes_id != null){
                       Object.assign(nodeData.node_data.next_node.branch_nodes_id, branchingObj);
                    }else{
                         nodeData.node_data.next_node.branch_nodes_id=branchingObj;
                     }

                     }
                     Object.assign(val,parentObj);

                   })

                }
              }else if(parentType.length == 0 ){
                 parentType.push(parentObj);
              }


                       if (nodeData != undefined && nodeData != "" && nodeData != null && nodeId > 0) {

                        updateService.updateNodeData(targetNodeData, targetNodeId).subscribe((resp) => {
                                if (resp.statusCode == 200) {
                                   console.log("success");
                                }
                              }) ;

                          updateService.updateNodeData(nodeData, nodeId).subscribe((response) => {
                            if (response.statusCode == 200) {


                              let nodes:any = [];
                              nodes = botDataService.dataToTransmit;
                              nodes.forEach((node , i)=>{
                                if(node.node_id == response["result"].node_id){
                                  nodes.splice(i,1,response["result"]);
                                }
                              })
                              botDataService.dataToTransmit = nodes;
                              this.chatObj = {};
                              this.chatObj["is_active"] = false;
                              this.chatObj["name"] = response["result"].name;
                              this.chatObj["bot_id"] = response["result"].bot_id;
                              botDataService.showOtherTabs(this.chatObj);
                              toastr.success('Node updated successfully');
                            }
                            else if(response.statusCode == 400){
                                var connections = jsplumbConst.getConnections({
                                  source: info.sourceId ,
                                  target: info.targetId
                                });
                                for(var i=0;i < connections.length;i++) {
                                  jsplumbConst.deleteConnection(connections[i]);
                                }
                            }
                            this.node = response;
                            if(branching){
                              // window.location.reload();
                            }
                          })
                    }
                  }
                }

            });
          }
    }
  });


    // connection update code ends ***********
    // draggable event starts ********
    this.jsPlumbInstance.draggable(node_id, {
      start: function (e) {
      },
      drag: function (event, ui) {
      },
      stop: function (event, ui) {
        let nodes = botDataService.dataToTransmit;
        let nodeData: any = {}, nodeId: number = 0;
        nodes.forEach(node => {
          if (node.node_id == node_id) {
              nodeId = node.id;

              node.node_data.pos_top = event.el.offsetTop,
              node.node_data.pos_left = event.el.offsetLeft,

              nodeData = {
                bot_id: node.bot_id,
                node_id: node.node_id,
                node_name: node.node_name,
                node_type: node.node_type,
                node_sub_type: node.node_sub_type,
                node_data: node.node_data,
                goal_name:node.goal_name,
                goal_value:node.goal_value,
                goal_unit:node.goal_unit
              }
          }
        });
        scrollPosition(event.el.offsetLeft , event.el.offsetTop);

        if (nodeData != undefined && nodeData != "" && nodeData != null && nodeId > 0) {
          updateService.updateNodeData(nodeData, nodeId).subscribe((response) => {
            if (response.statusCode == 200) {

              let nodes:any = [];
              nodes = botDataService.dataToTransmit;
              nodes.forEach((node , i)=>{
                    if(node.node_id == response["result"].node_id){
                         nodes.splice(i,1,response["result"]);
                    }
              })
              botDataService.dataToTransmit = nodes;
              this.chatObj = {};
              this.chatObj["is_active"] = false;
              this.chatObj["name"] = response["result"].name;
              this.chatObj["bot_id"] = response["result"].bot_id;
              let posTop=response["result"]["node_data"]["pos_top"]
              let posLeft=response["result"]["node_data"]["pos_left"]
              let lastNodePos=sessionStorage.getItem("post_top");
              let lastNodeLeft=sessionStorage.getItem("post_left");
              let lastNodeId=sessionStorage.getItem("last_node_id");
              if(response["result"]["node_id"]==lastNodeId){
                sessionStorage.setItem('post_top', posTop);
                 sessionStorage.setItem('post_left', posLeft);
              }

              botDataService.showOtherTabs(this.chatObj);
              toastr.success('Node updated successfully');
            }
            this.node = response;
          })
        }
      }
    });


    // draggable event ends ********
  }

  //****************** scroll control function on drag *************
  scrollPosition(posx,posy) {
        if(posy > 300 && posx > 300){
          window.scrollTo(window.pageXOffset+200, window.pageYOffset+200);
        }else if(posy < 300 && posx > 300){
          window.scrollTo(window.pageXOffset+200, 0);
        }else if(posy > 300 && posx < 300){
          window.scrollTo(0, window.pageYOffset+200);
        }else if(posy < 300 && posx < 300){
          window.scrollTo(0, 0);
        }

  }
}




